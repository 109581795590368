// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-address-jsx": () => import("./../../../src/pages/address.jsx" /* webpackChunkName: "component---src-pages-address-jsx" */),
  "component---src-pages-artist-artists-json-slug-jsx": () => import("./../../../src/pages/artist/{artistsJson.slug}.jsx" /* webpackChunkName: "component---src-pages-artist-artists-json-slug-jsx" */),
  "component---src-pages-card-cards-json-number-jsx": () => import("./../../../src/pages/card/{cardsJson.number}.jsx" /* webpackChunkName: "component---src-pages-card-cards-json-number-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

